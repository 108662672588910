// extracted by mini-css-extract-plugin
export var area = "EnterpriseDetails-module--area--f7qYA";
export var arrow = "EnterpriseDetails-module--arrow--qD0sA";
export var bar = "EnterpriseDetails-module--bar--8qalF";
export var beachfront = "EnterpriseDetails-module--beachfront--EXtEY";
export var bed = "EnterpriseDetails-module--bed--apO53";
export var btnWrapper = "EnterpriseDetails-module--btn-wrapper--XWgXd";
export var car = "EnterpriseDetails-module--car--iuB6z";
export var commercialRoom = "EnterpriseDetails-module--commercialRoom--zFg8-";
export var container = "EnterpriseDetails-module--container--7DHr4";
export var content = "EnterpriseDetails-module--content--D2mt0";
export var elements = "EnterpriseDetails-module--elements--oc5Gv";
export var form = "EnterpriseDetails-module--form--PELme";
export var image = "EnterpriseDetails-module--image--gEUSk";
export var intro = "EnterpriseDetails-module--intro--zx7SQ";
export var knowMore = "EnterpriseDetails-module--knowMore--cfyAI";
export var legal = "EnterpriseDetails-module--legal--tMJHf";
export var light = "EnterpriseDetails-module--light--f8hb4";
export var links = "EnterpriseDetails-module--links--4fcm5";
export var logo = "EnterpriseDetails-module--logo--ymwtU";
export var numbers = "EnterpriseDetails-module--numbers--5GEgr";
export var obs = "EnterpriseDetails-module--obs--9tafI";
export var parking = "EnterpriseDetails-module--parking--78wpq";
export var plant = "EnterpriseDetails-module--plant--507tO";
export var plantSlider = "EnterpriseDetails-module--plant-slider--pwYZx";
export var plantsWork = "EnterpriseDetails-module--plants-work--+Odl6";
export var slide = "EnterpriseDetails-module--slide--0yzpH";
export var slider = "EnterpriseDetails-module--slider--JXWTn";
export var stages = "EnterpriseDetails-module--stages--8QDyy";
export var types = "EnterpriseDetails-module--types--PYbB+";
export var video = "EnterpriseDetails-module--video--S9eBz";
export var videoMosaic = "EnterpriseDetails-module--video-mosaic--VyMhk";
export var videoWrapper = "EnterpriseDetails-module--videoWrapper--ydywW";
export var workImages = "EnterpriseDetails-module--work-images--VTuSr";